/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    Grid,
    useMediaQuery,
    TextField,
    FormControl,
    Typography,
    Box,
    IconButton,
    Button,
    Stack,
    InputAdornment
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MainCard from 'ui-component/cards/MainCard';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';

// assets
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Search } from '@mui/icons-material';
import useLeagues from 'hooks/useLeagues';
import PlayerCell from 'views/components/playerCell.component';
import { dispatch, useSelector } from 'store';
import useTournaments from 'hooks/useTournaments';
import actionSnackbar from 'ui-component/actionSnackbar';
import { searchUsers } from 'store/slices/user';

// ==============================|| MAIL DRAWER ||============================== //

const AddMatchDrawer = ({
    handleDrawerOpen,
    openSidebar,
    eventId,
    selectedPlayingGroupId,
    isAdmin = false,
    allowTeamChange = false,
    showTeamDelete = true
}) => {
    const theme = useTheme();
    const { addMatch } = useTournaments();
    const { deleteTeamMember } = useTournaments();
    const { setEventMatchups, eventMatchups, getLeagueGroupMatches } = useLeagues();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const [match, setMatch] = useState({ team_a: { players: [] }, team_b: { players: [] } });
    const [playerSearch, setPlayerSearch] = useState({ team_a: '', team_b: '' });
    const [maxNumGames, setMaxNumGames] = useState('1');
    const [teamChange, setTeamChange] = useState(false);

    const isSinglesOrDoubles = () =>
        (match.team_a.players.length === 1 && match.team_b.players.length === 1) ||
        (match.team_a.players.length === 2 && match.team_b.players.length === 2);

    const submitNewMatch = () => {
        if (!isSinglesOrDoubles()) {
            return;
        }
        if (maxNumGames < 1 || maxNumGames > 7) {
            return;
        }
        addMatch({
            division_id: eventId,
            type: match.team_a.players.length === 1 ? 'singles' : 'doubles',
            team_a: match.team_a.players,
            team_b: match.team_b.players,
            playing_group_id: selectedPlayingGroupId,
            num_games: maxNumGames
        }).then((_) => {
            getLeagueGroupMatches(selectedPlayingGroupId).then((res) => {
                if (res) {
                    setEventMatchups(res?.matches);
                } else {
                    setEventMatchups([]);
                }
            });
            setMatch({ team_a: { players: [] }, team_b: { players: [] } });
        });
        handleDrawerOpen();
    };

    const handlePlayerSearch = (event, team) => {
        const newInputValue = event.target.value;
        setPlayerSearch((prevState) => ({ ...prevState, [team]: newInputValue, [team === 'team_a' ? 'team_b' : 'team_a']: '' }));
        dispatch(searchUsers(newInputValue));
    };
    const { userList } = useSelector((state) => state.user);

    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50';

    const handleRemoveTeam = (team) => {
        setTeamChange(true);
        if (team === 'team_a') {
            setMatch((prevState) => ({
                ...prevState,
                team_a: null,
                team_a_id: null
            }));
        } else {
            setMatch((prevState) => ({
                ...prevState,
                team_b: null,
                team_b_id: null
            }));
        }
    };

    const handleChangeMaxNumGames = (e) => {
        const numericValue = e.target.value;
        setMaxNumGames(numericValue);
    };

    return (
        <Drawer
            data-test-id="views/admin/AdminHub/TournamentManager/AddMatchDrawer"
            sx={{
                ml: openSidebar ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={openSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            {openSidebar && (
                <MainCard
                    sx={{
                        bgcolor: matchDownLG ? 'transparent' : drawerBG,
                        paddingBottom: 150
                    }}
                    border={!matchDownSM}
                    content={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[6]}
                >
                    <CardContent
                        sx={{
                            height: matchDownSM ? '100vh' : 'auto', // Adjust the height as needed
                            overflowY: 'auto' // Change to 'hidden' to ensure PerfectScrollbar takes control
                        }}
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h3"> New Match</Typography>
                                    <Box>
                                        <IconButton size="small" onClick={() => handleDrawerOpen()}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Grid mt={3}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="h5"> Team 1</Typography>
                                            {match?.team_a && isAdmin && showTeamDelete && (
                                                <IconButton sx={{ padding: 0 }} onClick={() => handleRemoveTeam('team_a')}>
                                                    <DeleteTwoToneIcon color="error" />
                                                </IconButton>
                                            )}
                                        </Stack>
                                        {match?.team_a &&
                                            match?.team_a?.players?.map((player, index) => (
                                                <PlayerCell
                                                    user={player}
                                                    userAttributes={player?.user_attributes}
                                                    backgroundColor="#E6E7EC"
                                                    padding={1}
                                                    borderRadius={3}
                                                    showDelete={allowTeamChange}
                                                    deleteAction={() => {
                                                        deleteTeamMember(player?.id).then(() => {
                                                            actionSnackbar(true, 'Player removed from team');
                                                            setMatch((prevMatch) => ({
                                                                ...prevMatch,
                                                                team_a: {
                                                                    ...prevMatch.team_a,
                                                                    players: prevMatch.team_a.players.filter((_, i) => i !== index)
                                                                }
                                                            }));
                                                        });
                                                    }}
                                                />
                                            ))}
                                        <FormControl sx={{ width: '100%', mt: 1 }}>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => handlePlayerSearch(e, 'team_a')}
                                                placeholder="Search Players"
                                                value={playerSearch.team_a}
                                                size="small"
                                            />
                                            {playerSearch?.team_a?.length > 0 && (
                                                <MainCard border={false} elevation={4} content={false} boxShadow sx={{ padding: 2 }}>
                                                    {userList.map((user, index) => (
                                                        <Grid key={index} item xs={12}>
                                                            <PlayerCell
                                                                user={user}
                                                                userAttributes={user?.user_attributes}
                                                                backgroundColor="#E6E7EC"
                                                                padding={1}
                                                                borderRadius={3}
                                                                onClick={() => {
                                                                    setPlayerSearch((prevState) => ({
                                                                        ...prevState,
                                                                        team_a: ''
                                                                    }));
                                                                    actionSnackbar(true, 'Player added to team');
                                                                    setMatch((prevMatch) => ({
                                                                        ...prevMatch,
                                                                        team_a: {
                                                                            ...prevMatch.team_a,
                                                                            players: [...prevMatch.team_a.players, user]
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </MainCard>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid mt={3}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="h5"> Team 2</Typography>
                                            {match?.team_b && isAdmin && showTeamDelete && (
                                                <IconButton sx={{ padding: 0 }} onClick={() => handleRemoveTeam('team_b')}>
                                                    <DeleteTwoToneIcon color="error" />
                                                </IconButton>
                                            )}
                                        </Stack>

                                        {match?.team_b &&
                                            match?.team_b?.players.map((player, index) => (
                                                <PlayerCell
                                                    key={index}
                                                    user={player}
                                                    userAttributes={player?.user_attributes}
                                                    backgroundColor="#E6E7EC"
                                                    padding={1}
                                                    borderRadius={3}
                                                    showDelete={allowTeamChange}
                                                    deleteAction={() => {
                                                        deleteTeamMember(player?.id).then(() => {
                                                            actionSnackbar(true, 'Player removed from team');
                                                            setMatch((prevMatch) => ({
                                                                ...prevMatch,
                                                                team_b: {
                                                                    ...prevMatch.team_b,
                                                                    players: prevMatch.team_b.players.filter((_, i) => i !== index)
                                                                }
                                                            }));
                                                        });
                                                    }}
                                                />
                                            ))}
                                        <FormControl sx={{ width: '100%', mt: 1 }}>
                                            <TextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) => handlePlayerSearch(e, 'team_b')}
                                                placeholder="Search Players"
                                                value={playerSearch.team_b}
                                                size="small"
                                            />
                                            {playerSearch?.team_b?.length > 0 && (
                                                <MainCard border={false} elevation={4} content={false} boxShadow sx={{ padding: 2 }}>
                                                    {userList.map((user, index) => (
                                                        <Grid key={index} item xs={12}>
                                                            <PlayerCell
                                                                user={user}
                                                                userAttributes={user?.user_attributes}
                                                                backgroundColor="#E6E7EC"
                                                                padding={1}
                                                                borderRadius={3}
                                                                onClick={() => {
                                                                    setPlayerSearch((prevState) => ({
                                                                        ...prevState,
                                                                        team_b: ''
                                                                    }));
                                                                    actionSnackbar(true, 'Player added to team');
                                                                    setMatch((prevMatch) => ({
                                                                        ...prevMatch,
                                                                        team_b: {
                                                                            ...prevMatch.team_b,
                                                                            players: [...prevMatch.team_b.players, user]
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </MainCard>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid mt={3}>
                                    <Typography variant="h5"> Max Number of Games</Typography>
                                    <FormControl sx={{ width: '100%', mt: 1 }} type="number">
                                        <TextField
                                            value={maxNumGames}
                                            onChange={handleChangeMaxNumGames}
                                            placeholder="Max Games"
                                            type="number"
                                            size="small"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="submit"
                                        onClick={submitNewMatch}
                                        startIcon={<PersonOutlineIcon stroke={1.5} size="20px" />}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

AddMatchDrawer.propTypes = {
    handleDrawerOpen: PropTypes.func,
    openSidebar: PropTypes.bool
};

export default AddMatchDrawer;
