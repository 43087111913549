import React from 'react';
import { IconButton } from '@mui/material';
import { AccessTime, CheckCircle } from '@mui/icons-material';
import useLeagues from 'hooks/useLeagues';

const MatchUploadedToDuprIndicator = ({ match, isAdmin }) => {
    const { uploadMatchesToDupr } = useLeagues();

    const uploadMatchesToDuprIfAdmin = (matchId) => {
        if (isAdmin) {
            uploadMatchesToDupr(matchId);
        }
    };

    if (!match?.winner_id) {
        return null;
    }
    if (match?.dupr_match_code) {
        return <CheckCircle color="success" />;
    }
    return (
        <IconButton onClick={() => uploadMatchesToDuprIfAdmin(match.id)}>
            <AccessTime color="warning" />
        </IconButton>
    );
};

export default MatchUploadedToDuprIndicator;
