/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable radix */
import React, { useState } from 'react';
import {
    Button,
    Card,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { Print } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import useTournaments from 'hooks/useTournaments';
import AnimateButton from 'ui-component/extended/AnimateButton';
import MatchUploadedToDuprIndicator from './MatchUploadedToDuprIndicator';
import useLeagues from 'hooks/useLeagues';
import AddMatchDrawer from '../EventInfo/components/AddMatchDrawer';

const MatchTableRow = ({ match, isAdmin, handleDrawerOpen, setSelectedMatchId }) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [printMatchId, setPrintMatchId] = useState(null); // State to track the match being printed
    const { printMatches, loading } = useTournaments();
    const { deleteMatch } = useLeagues();

    const handleClose = () => setMenuAnchorEl(null);

    const handlePrintClick = async (matchId) => {
        setPrintMatchId(matchId);
        await printMatches({ match_id: matchId });
        setPrintMatchId(null); // Reset the state after printing
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleDeleteButtonClick = (match) => {
        handleClose();
        deleteMatch(match?.id);
    };

    return (
        <TableRow key={match.id}>
            <TableCell>
                <Link
                    component="button"
                    disabled={!isAdmin}
                    onClick={() => {
                        setSelectedMatchId(match.id);
                        handleDrawerOpen();
                    }}
                >
                    {match.id}
                </Link>
            </TableCell>
            <TableCell>
                <Typography>{match.court?.name}</Typography>
            </TableCell>
            <TableCell>
                <MatchUploadedToDuprIndicator match={match} isAdmin={isAdmin} />
            </TableCell>
            <TableCell>
                <CondensedTeamCard match={match} party={match.team_a} />
            </TableCell>
            <TableCell>
                {match?.match_games?.map((item, index) => {
                    const wonGame =
                        parseInt(item?.game_scores.find((score) => score?.team_id === match?.team_a?.id)?.score) >
                        parseInt(match?.match_games[index]?.game_scores.find((score) => score?.team_id === match?.team_b?.id)?.score);
                    const gameScores = item?.game_scores.find((score) => score?.team_id === match?.team_a?.id)?.score;
                    if (gameScores !== undefined) {
                        return (
                            <Grid
                                mt={0.5}
                                sx={{
                                    backgroundColor: wonGame ? 'green' : 'red',
                                    textAlign: 'center',
                                    paddingX: 1,
                                    paddingY: 0.25
                                }}
                                key={item?.id}
                            >
                                <Typography variant="h5" color="white">
                                    {gameScores}
                                </Typography>
                            </Grid>
                        );
                    }
                    return null;
                })}
            </TableCell>
            <TableCell>VS</TableCell>
            <TableCell>
                {match?.match_games?.map((item, index) => {
                    const wonGame =
                        parseInt(item?.game_scores?.find((score) => score?.team_id === match?.team_b?.id)?.score) >
                        parseInt(match?.match_games[index]?.game_scores?.find((score) => score?.team_id === match?.team_a?.id)?.score);
                    const gameScores = item?.game_scores?.find((score) => score?.team_id === match?.team_b?.id)?.score;
                    if (gameScores !== undefined) {
                        return (
                            <Grid
                                mt={0.5}
                                sx={{
                                    backgroundColor: wonGame ? 'green' : 'red',
                                    textAlign: 'center',
                                    paddingX: 1,
                                    paddingY: 0.25
                                }}
                                key={item?.id}
                            >
                                <Typography variant="h5" color="white">
                                    {gameScores}
                                </Typography>
                            </Grid>
                        );
                    }
                    return null;
                })}
            </TableCell>
            <TableCell>
                <CondensedTeamCard match={match} party={match.team_b} />
            </TableCell>
            {isAdmin && (
                <TableCell>
                    <IconButton onClick={() => handlePrintClick(match.id)} disabled={loading?.printMatches && printMatchId === match.id}>
                        {loading?.printMatches && printMatchId === match.id ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Print color={match?.printed ? 'success' : 'grey'} />
                        )}
                    </IconButton>
                </TableCell>
            )}
            {isAdmin && (
                <TableCell>
                    <Chip
                        size="medium"
                        label="Actions"
                        onClick={(e) => handleMenuClick(e)}
                        sx={{
                            mt: 1,
                            borderRadius: '10%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    />
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        keepMounted
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem>
                            <AnimateButton>
                                <Button type="submit" variant="contained" color="error" onClick={() => handleDeleteButtonClick(match)}>
                                    Delete
                                </Button>
                            </AnimateButton>
                        </MenuItem>
                    </Menu>
                </TableCell>
            )}
        </TableRow>
    );
};

const MatchesTable = ({ eventId, handleDrawerOpen, setSelectedMatchId, isAdmin, type, selectedPlayingGroupId }) => {
    console.log('playing group id', selectedPlayingGroupId);
    const [openReplaceUserSidebar, setOpenReplaceUserSidebar] = useState(false);
    const { eventMatchups } = useLeagues();

    const handleReplaceDrawerOpen = () => {
        setOpenReplaceUserSidebar(!openReplaceUserSidebar);
    };

    return (
        <Grid data-test-id="views/admin/AdminHub/TournamentManager/MatchesTable">
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Match ID</TableCell>
                                <TableCell> Court</TableCell>
                                <TableCell> DUPR </TableCell>
                                <TableCell align="center">Team</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell sx={{ marginLeft: 2 }} align="center">
                                    Team
                                </TableCell>
                                {isAdmin && <TableCell>Print</TableCell>}
                                {isAdmin && type === 'league' && <TableCell>Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventMatchups?.map((match) => (
                                <MatchTableRow
                                    match={match}
                                    handleDrawerOpen={handleDrawerOpen}
                                    setSelectedMatchId={setSelectedMatchId}
                                    isAdmin={isAdmin}
                                    key={match?.id}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isAdmin && type === 'league' && (
                    <Button startIcon={<AddIcon />} onClick={handleReplaceDrawerOpen} sx={{ color: 'primary' }}>
                        Add A Match
                    </Button>
                )}
                {isAdmin && type === 'league' && (
                    <AddMatchDrawer
                        openSidebar={openReplaceUserSidebar}
                        handleDrawerOpen={handleReplaceDrawerOpen}
                        // leagueId={leagueId}
                        // existingUsers={rows}
                        // replacedUser={replacedUser}
                        isAdmin
                        type={type}
                        eventId={eventId}
                        selectedPlayingGroupId={selectedPlayingGroupId}
                    />
                )}
            </Card>
        </Grid>
    );
};

export default MatchesTable;
